@import 'utilities';

.mat-mdc-card.title-card {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  border: 1px solid border('card');

  @include mq(lt-md) {
    border-radius: 0;
    margin-bottom: 0;
    box-shadow: none;
    border: 0;
  }

  mat-toolbar {
    border-radius: 4px 4px 0 0;

    @include mq(lt-md) {
      border-radius: 0;
    }
  }

  &.no-padding {
    padding: 0;
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
    mat-card-content {
      padding: 0;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  mat-card-actions {
    padding: 8px 16px;
  }

  .mat-mdc-card-content {
    margin: 16px 0;
    padding: 0 16px;
  }

  .step-number {
    height: 30px;
    width: 30px;
    display: inline-block;
    border: solid 3px #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 1rem;
    line-height: 24px;
    margin-right: 10px;
    position: relative;
    text-align: center;
    flex-shrink: 0;

    &--dec {
      height: 26px;
      width: 26px;
      line-height: 24px;
      border-width: 2px;
    }
  }

  .steps-text {
    background-color: $ids-brand-color;
    font-size: 20px;
    font-weight: 500;
    padding: .625rem 1rem;
    // Material Overrides
    line-height: 1.3;
    max-height: none;
    height: auto;
    white-space: normal;

    &--dec {
      padding: 7px 1rem;
      font-size: 1.125rem;
      background-color: $dec-title-card-bg;
    }
  }
}
