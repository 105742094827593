// This file should contain all global variables used throughout the application. All other .scss files should consume
// these variables. This gives us the benefit of having one central location where we can reference variable values.

$red: #e85255;
$purple: #184a67;
$orange: #ff6e35;
$green: #090;

$ids-brand-color: #0ba8cf;

$dec-brand-color: #008ed3;
$dec-purple: #4d7285;
$dec-purple-dark: #37515e;
$dec-title-card-bg: #4cb0e0;

$grays: (
  '100': #f8f9fa,
  '200': #e9ecef,
  '300': #dee2e6,
  '400': #ced4da,
  '500': #adb5bd,
  '600': #6c757d,
  '700': #495057,
  '800': #343a40,
  '900': #212529
);

// Helper function to easily retrieve gray colors
@function gray($key: '100') {
  @if map-has-key($grays, $key) {
    @return map-get($grays, $key);
  }

  @else {
    @error 'Invalid key: #{$key}.';
  }
}

$border-colors: (
  'form-field': gray('400'), // Form field input border
  'card': gray('300'), // Material card border
);

// Helper function to easily retrieve border colors
@function border($key: 'form-field') {
  @if map-has-key($border-colors, $key) {
    @return map-get($border-colors, $key);
  }

  @else {
    @error 'Invalid key: #{$key}.';
  }
}
