// Media queries are an exact representation of those used by @angular/flex-layout
$media-queries: (
  xs: '(min-width: 0) and (max-width: 599.9px)',
  sm: '(min-width: 600px) and (max-width: 959.9px)',
  md: '(min-width: 960px) and (max-width: 1279.9px)',
  lg: '(min-width: 1280px) and (max-width: 1919.9px)',
  xl: '(min-width: 1920px) and (max-width: 4999.9px)',
  lt-sm: '(max-width: 599.9px)',
  lt-md: '(max-width: 959.9px)',
  lt-lg: '(max-width: 1279.9px)',
  lt-xl: '(max-width: 1919.9px)',
  gt-xs: '(min-width: 600px)',
  gt-sm: '(min-width: 960px)',
  gt-md: '(min-width: 1280px)',
  gt-lg: '(min-width: 1920px)'
);

@mixin mq($mq) {
  @if map-has-key($media-queries, $mq) {
    @media screen and #{map-get($media-queries, $mq)} {
      @content;
    }
  }

  @else {
    @error 'Invalid media query mixin value: #{$mq}.';
  }
}

// USAGE:
// ----------
// 1. @import "media-query-mixin"; <-- This must be included at the top of your .scss file to import the mixin
// 2. Now you can use the mixin anywhere in the .scss file.
// 3. EXAMPLE:
//    **SCSS INPUT**
//    .my-link {
//      color: blue;
//      // Override the color property for mobile only
//      @include mq(xs) {
//        color: aqua;
//      }
//    }
//
//    **CSS OUTPUT**:
//    .link {
//      color: blue;
//    }
//    @media screen and (min-width: 0) and (max-width: 599.9px) {
//      .link {
//        color: aqua;
//      }
//    }
