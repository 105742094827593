/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'styles/title-card';

.loading {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
}

html,
body {
  @apply h-full box-border;
}

body {
  @apply m-0 font-primary text-dark;
  font-display: swap;
  h1 {
    @apply text-4xl sm:text-5xl leading-h1-mobile sm:leading-h1 font-extrabold font-secondary;
  }

  h2 {
    @apply text-h2-mobile sm:text-h2 leading-h2-mobile sm:leading-10 font-extrabold font-secondary;
  }

  h3 {
    @apply text-2xl leading-h3 font-h3 font-secondary;
  }

  h4 {
    @apply text-xl leading-h4 font-extrabold font-secondary;
  }

  a {
    @apply link;
  }

  button,
  .claim-button {
    @apply cta-button;
    &:hover {
      @apply cta-button-hover;
    }
    &:focus {
      @apply cta-button-pressed;
    }
    &:disabled {
      @apply cta-button-disabled;
    }
    &.cta-button-secondary {
      &:hover {
        @apply cta-button-secondary-hover;
      }
      &:focus {
        @apply cta-button-secondary-pressed;
      }
      &:disabled {
        @apply cta-button-secondary-disabled;
      }
    }
    &.cta-button-tertiary,
    &.cta-button-tertiary .primary-text {
      &:hover {
        @apply cta-button-tertiary-hover;
      }
      &:focus {
        @apply cta-button-tertiary-pressed;
      }
      &:disabled {
        @apply cta-button-tertiary-disabled;
      }
    }
  }

  input,
  select {
    @apply border border-grey-500 bg-grey-100 rounded-md p-small text-dark;
  }

  select {
    @apply h-[52px] px-3 py-3.5 appearance-none;
  }

  p {
    @apply text-lg leading-p mb-3;
  }

  li {
    @apply text-lg leading-p;
  }

  ol {
    @apply list-decimal list-inside ml-6 text-lg mb-3 font-normal;
  }

  ul {
    @apply list-disc list-inside ml-6 text-lg mb-3 font-normal;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.xcc-container {
  @apply p-xcc-sm sm:py-xcc md:py-xcc-sm md:px-0 md:mx-auto md:max-w-[1164px] w-full;
}
.xcc-padding {
  @apply p-xcc-sm sm:p-xcc;
}
.xcc-header-spacing {
  @apply mb-4;
}
.input-icon {
  @apply absolute -top-0.5 right-3.5 text-xl;
}
.grecaptcha-badge {
  @apply invisible;
}
